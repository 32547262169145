import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import Timecode from 'smpte-timecode';

function App() {
  const [statusMessage, setStatusMessage] = useState('');
  const [baseTime, setBaseTime] = useState(null);
  const [currentTimecode, setCurrentTimecode] = useState('--:--:--:--');
  const [currentMs, setCurrentMs] = useState('---');
  const [detectedRefreshRate, setDetectedRefreshRate] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const frameRateParam = params.get('frameRate') || '29.97';
  const dropFrameParam = params.get('dropFrame') === 'true';
  const refreshRateParam = params.get('refreshRate');
  const textColorParam = params.get('textColor') || '#eee';

  let frameRate = parseFloat(frameRateParam);
  let dropFrame = dropFrameParam;

  const validDropFrameRates = [23.976, 29.97, 59.94];
  if (dropFrame && !validDropFrameRates.includes(frameRate)) {
    setStatusMessage(
      'Drop-frame not supported for this frame rate. Using non-drop-frame.'
    );
    dropFrame = false;
  }

  // If user provided refreshRate, use that. Otherwise, we will detect.
  const [refreshRate, setRefreshRate] = useState(
    refreshRateParam ? parseFloat(refreshRateParam) : null
  );

  const requestRef = useRef(null);
  const lastUpdateRef = useRef(performance.now());

  const containerRef = useRef(null);
  const textWrapperRef = useRef(null);

  const [scale, setScale] = useState(1);

  // Hidden elements for measurement
  const hiddenMeasureRef = useRef(null);
  const hiddenTimecodeRef = useRef(null);
  const hiddenMsRef = useRef(null);

  useEffect(() => {
    async function fetchTime() {
      setStatusMessage(
        refreshRate == null
          ? 'Fetching UTC time & detecting refresh rate...'
          : 'Fetching UTC time...'
      );
      try {
        const response = await fetch(
          'https://worldtimeapi.org/api/timezone/Etc/UTC'
        );
        if (!response.ok) throw new Error('Network response not ok');
        const data = await response.json();
        const serverTime = new Date(data.utc_datetime).getTime();
        setBaseTime(serverTime);
        if (refreshRate == null) {
          // If no refreshRate provided, detect it
          detectScreenRefreshRate().then((detectedFrameTime) => {
            const fps = 1000 / detectedFrameTime;
            setDetectedRefreshRate(fps);
            setRefreshRate(fps);
            setStatusMessage('');
          });
        } else {
          setStatusMessage('');
        }
      } catch (error) {
        setStatusMessage('Error fetching time, using local time.');
        setBaseTime(Date.now());
        if (refreshRate == null) {
          detectScreenRefreshRate().then((detectedFrameTime) => {
            const fps = 1000 / detectedFrameTime;
            setDetectedRefreshRate(fps);
            setRefreshRate(fps);
            setStatusMessage('');
          });
        } else {
          setStatusMessage('');
        }
      }
    }
    fetchTime();
  }, []);

  function getTimecodeFromUTC(utcTimeMs) {
    const tc = new Timecode(new Date(utcTimeMs), frameRate, dropFrame);
    return tc.toString();
  }

  useEffect(() => {
    if (baseTime !== null && refreshRate !== null) {
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [baseTime, refreshRate, frameRate, dropFrame]);

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate);
    if (!baseTime || refreshRate === null) return;

    const now = performance.now();
    const frameDuration = 1000 / refreshRate;

    if (now - lastUpdateRef.current >= frameDuration) {
      lastUpdateRef.current = now;
      const currentUtcMs = Date.now();
      const tcString = getTimecodeFromUTC(currentUtcMs);
      setCurrentTimecode(tcString);

      const ms = new Date(currentUtcMs)
        .getUTCMilliseconds()
        .toString()
        .padStart(3, '0');
      setCurrentMs(ms);
    }
  };

  useLayoutEffect(() => {
    if (
      !containerRef.current ||
      !hiddenMeasureRef.current ||
      currentTimecode === '--:--:--:--'
    )
      return;

    // Update hidden texts for measurement
    hiddenTimecodeRef.current.textContent = currentTimecode;
    hiddenMsRef.current.textContent = currentMs + ' ms';

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;

    const measuredWidth = hiddenMeasureRef.current.scrollWidth;
    const measuredHeight = hiddenMeasureRef.current.scrollHeight;

    const widthScale = containerWidth / measuredWidth;
    const heightScale = containerHeight / measuredHeight;
    const finalScale = Math.min(widthScale, heightScale);

    setScale(finalScale);
  }, [currentTimecode, currentMs]);

  return (
    <div className="container" ref={containerRef}>
      <div
        className="text-wrapper"
        ref={textWrapperRef}
        style={{
          transform: `translate(-50%, -50%) scale(${scale})`,
          left: '50%',
          top: '50%',
        }}
      >
        <div className="text-content">
          <div className="timecode-display" style={{ color: textColorParam }}>
            {currentTimecode}
          </div>
          <div
            className="millisecond-display"
            style={{ color: textColorParam }}
          >
            {currentMs} ms
          </div>
        </div>
      </div>

      <div id="status">{statusMessage}</div>

      {/* Hidden measurement structure */}
      <div className="hidden-measure" ref={hiddenMeasureRef}>
        <div
          className="timecode-display"
          ref={hiddenTimecodeRef}
          style={{ fontSize: '15vw', lineHeight: 1, color: textColorParam }}
        ></div>
        <div
          className="millisecond-display"
          ref={hiddenMsRef}
          style={{ fontSize: '10vw', lineHeight: 1, color: textColorParam }}
        ></div>
      </div>
    </div>
  );
}

export default App;

/** Utility to Detect Screen Refresh Rate **/
function detectScreenRefreshRate() {
  return new Promise((resolve) => {
    const checks = {
      frameRateChecks: 0,
      screenFrameTime: 0,
      prevTime: 0,
    };

    const frameTimeTable = (frameTime) => {
      if (frameTime > 15.5) return 16.66666666666667; // ~60Hz
      if (frameTime > 12.5) return 13.33333333333333; // ~75Hz
      if (frameTime > 10.5) return 11.111111111111111; // ~90Hz
      if (frameTime > 9.5) return 10; // ~100Hz
      if (frameTime > 8) return 8.33333333333333; // ~120Hz
      if (frameTime > 6.5) return 6.944444444444444; // ~144Hz
      if (frameTime > 5.5) return 6.0606060606060606; // ~165Hz
      if (frameTime > 3.5) return 4.166666666666667; // ~240Hz
      if (frameTime > 2.5) return 2.777777777777778; // ~360Hz
      return 2; // ~500Hz fallback
    };

    const check = (now) => {
      const dt = now - checks.prevTime;
      checks.prevTime = now;

      if (dt > 100) {
        requestAnimationFrame(check);
        return;
      }

      if (checks.frameRateChecks < 20) {
        requestAnimationFrame(check);
        checks.frameRateChecks++;
        checks.screenFrameTime =
          checks.screenFrameTime === 0 ? dt : (checks.screenFrameTime + dt) / 2;
      } else {
        checks.screenFrameTime = frameTimeTable(checks.screenFrameTime);
        resolve(checks.screenFrameTime);
      }
    };

    requestAnimationFrame((startTime) => {
      checks.prevTime = startTime;
      requestAnimationFrame(check);
    });
  });
}